































































































































import Vue from 'vue';
import GoogleAds from "@/components/GoogleAds.vue";
export default Vue.extend({
  name: 'App',
  data(){
    return{
      DarkMode : false,
      TextColor : false
    }
  },
  components: {
   GoogleAds
  }
});
